/** 车类别 */
export const CAR_CATEGORY_ENUM = [
  { label: "全部", value: "" },
  {
    value: "铁皮车",
    label: "铁皮车",
  },
  {
    value: "冷藏车",
    label: "冷藏车",
  },
  {
    value: "高栏车",
    label: "高栏车",
  },
];

/** 车型枚举 */
export const VEHICLE_MODEL = [
  { label: "全部", value: "" },
  {
    value: "4.2米以下",
    label: "4.2米以下",
  },
  {
    value: "4.2米",
    label: "4.2米",
  },
  {
    value: "5.2米",
    label: "5.2米",
  },
  {
    value: "6.8米",
    label: "6.8米",
  },
  {
    value: "7.7米",
    label: "7.7米",
  },
  {
    value: "9.6米",
    label: "9.6米",
  },
  {
    value: "13米及以上",
    label: "13米及以上",
  },
];

/** 条件 */
export const CONDITION_ENUM = [
  { label: "全部", value: "" },
  {
    value: 1,
    label: "大于",
  },
  {
    value: 2,
    label: "大于等于",
  },
  {
    value: 3,
    label: "小于",
  },
  {
    value: 4,
    label: "小于等于",
  },
];
