<template>
  <div>
    <!-- 变更记录 -->
    <el-dialog
      title="变更记录"
      :visible.sync="dialogFormVisible"
      width="75%"
      class="dialog"
      center
    >
      <div class="contents1">
        <div class="seach">
          <div class="inputName">
            <div class="name">集配中心：</div>
            <div class="inputs">
              <el-select
                v-model="formData.logistic_business_id"
                clearable
                placeholder="请选择集配中心"
              >
                <el-option
                  v-for="item of logisticsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="inputName">
            <div class="name">车型：</div>
            <div class="inputs">
              <el-select
                v-model="formData.vehicle_model_name"
                clearable
                placeholder="请选择车型"
              >
                <el-option
                  v-for="item of VEHICLE_MODEL"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="inputName">
            <div class="name">条件：</div>
            <div class="inputs">
              <el-select
                v-model="formData.symbol"
                clearable
                placeholder="请选择条件"
              >
                <el-option
                  v-for="item of CONDITION_ENUM"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="inputName">
            <div class="name">操作人：</div>
            <div class="inputs">
              <el-input
                v-model="formData.operate_name"
                placeholder="输入操作人"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="inputName">
            <div class="name">修改时间：</div>
            <el-date-picker
              @change="timeChange"
              v-model="pay_at"
              :time-arrow-control="true"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="—"
              start-placeholder="修改时间起"
              end-placeholder="修改时间止"
            >
            </el-date-picker>
          </div>
          <div class="rightBtn">
            <el-button @click="search" type="primary" icon="el-icon-search"
              >查询</el-button
            >
          </div>
        </div>
        <div class="content" v-loading="dialogLoading">
          <el-table
            :data="tableData"
            height="100%"
            :border="true"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
          >
            <el-table-column
              prop="vehicle_model_name"
              align="center"
              min-width="120"
              label="车型"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="symbol_txt" align="center" label="条件">
            </el-table-column>
            <el-table-column prop="intro" align="center" label="修改内容">
            </el-table-column>
            <el-table-column prop="update_time" align="center" label="修改时间">
            </el-table-column>
            <el-table-column prop="operate_name" align="center" label="修改人">
            </el-table-column>
          </el-table>
          <!-- 分页 -->
        </div>
        <div class="pagination-wrap">
          <el-pagination
            type="primary"
            background
            @size-change="onHandleSizeChange"
            @current-change="onHandleCurrentChange"
            :current-page="formData.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { postLogisticsBasicFeeLog } from "@/api/general/cost.js";
import {
  CAR_CATEGORY_ENUM,
  CONDITION_ENUM,
  VEHICLE_MODEL,
} from "../../utils/enum/index";

export default {
  name: "changeRecordDialog",
  props: ["logisticsList"],
  data() {
    return {
      CAR_CATEGORY_ENUM,
      CONDITION_ENUM,
      VEHICLE_MODEL,
      dialogLoading: false,
      formData: {
        logistic_business_id: "",
        vehicle_model_name: "",
        symbol: "",
        operate_name: "",
        start_date: "",
        end_date: "",
        page: 1,
        pageSize: 50,
      },
      pay_at: "",
      dialogFormVisible: false, //编辑弹窗
      tableData: [],
      total: 0,
    };
  },
  created() {},
  methods: {
    // 修改时间
    timeChange(e) {
      if (e) {
        this.formData.start_date = e[0];
        this.formData.end_date = e[1];
      } else {
        this.formData.start_date = "";
        this.formData.end_date = "";
      }
    },
    // 搜索
    search() {
      this.formData.page = 1;
      this.postAjaxLogisticsBasicFeeLog();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxLogisticsBasicFeeLog();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxLogisticsBasicFeeLog();
    },
    /**
     *初始化数据
     */
    initData(e) {
      this.dialogFormVisible = true;
      this.pay_at = "";
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.formData.logistic_business_id = e.logistic_business_id;
      this.formData.vehicle_model_name = e.vehicle_model_name;
      this.formData.symbol = e.symbol;
      this.postAjaxLogisticsBasicFeeLog();
    },
    // 变更记录列表
    async postAjaxLogisticsBasicFeeLog() {
      this.dialogLoading = true;
      try {
        const res = await postLogisticsBasicFeeLog(this.formData);
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postLogisticsBasicFeeLog");
      } finally {
        this.dialogLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 15px;

  .seach {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .inputName {
      display: flex;
      align-content: center;
    }
    .name {
      width: 95px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .inputs {
      display: flex;
      align-items: center;
      width: 220px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    .rightBtn {
      margin-left: 10px;
    }
  }
  .content {
    height: 400px;
    border: 1px solid #ebeef5;
  }
  .pagination-wrap {
    box-sizing: border-box;
    padding: 10px 0;
  }
}
</style>
