<template>
  <div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogFormVisible"
      width="50%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-descriptions title="" :column="1">
          <el-descriptions-item label="集配中心">
            {{
              logisticsList.length > 0 &&
              logisticsList.find(
                (item) => item.id == detail.logistic_business_id
              )
                ? logisticsList.find(
                    (item) => item.id == detail.logistic_business_id
                  ).name
                : "-"
            }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="1">
          <el-descriptions-item label="车型">
            {{ detail.vehicle_model_name || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="基点">
            {{ detail.threshold_txt || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="条件">
            {{ detail.symbol_txt || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="合车费">
            <el-input
              v-model="detail.merge_fare"
              placeholder="请输入"
              @input="(val) => handleInput(val, 'merge_fare')"
            ></el-input
            >元
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="装卸费">
            <el-input
              v-model="detail.handling_cost"
              placeholder="请输入"
              @input="(val) => handleInput(val, 'handling_cost')"
            ></el-input
            >元
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="场地费">
            <el-input
              v-model="detail.site_cost"
              placeholder="请输入"
              @input="(val) => handleInput(val, 'site_cost')"
            >
            </el-input
            >元
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="保险费">
            <el-input
              v-model="detail.premium"
              placeholder="请输入"
              @input="(val) => handleInput(val, 'premium')"
            ></el-input
            >元
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="2">
          <el-descriptions-item label="变更备注">
            <el-input
              v-model="remark"
              placeholder="请输入变更备注"
              type="textarea"
              :rows="3"
              maxlength="128"
              show-word-limit
            ></el-input
          ></el-descriptions-item>
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom" :loading="submitLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { postLogisticsBasicFeeUpdate } from "@/api/general/cost.js";

export default {
  name: "editConfigDialog",
  props: ["logisticsList"],
  data() {
    return {
      loading: false,
      submitLoading: false,
      dialogFormVisible: false, //编辑弹窗
      detail: {},
      remark: "", // 变更备注
    };
  },
  created() {},
  methods: {
    handleInput(value, e) {
      console.log(value, e, "22");
      let dat =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      this.detail[e] = dat;
    },
    /**
     *初始化数据
     */
    initData(e) {
      this.detail = JSON.parse(JSON.stringify(e));
      this.remark = "";
      this.dialogFormVisible = true;
    },
    /**
     * 提交
     */
    async submitFrom() {
      if (!this.remark) {
        this.$message.error("请填写变更备注");
        return;
      }
      this.submitLoading = true;
      try {
        const res = await postLogisticsBasicFeeUpdate({
          ...this.detail,
          remark: this.remark,
        });
        this.$message.success("编辑成功");
        this.$emit("submit-form");
        this.dialogFormVisible = false;
      } catch (error) {
        console.log(error, "postLogisticsBasicFeeUpdate");
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;

  .hide {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
  .tipWrap {
    color: red;
    margin-left: 10px;
  }
}

/deep/ .custom-select-dropdown {
  .el-select-dropdown__wrap {
    max-height: 300px !important;
    overflow-y: auto !important;
    box-sizing: border-box;
  }
}

/deep/ .el-descriptions-item__cell {
  line-height: 40px !important;
}
/deep/ .el-input {
  width: 200px !important;
  margin-right: 10px !important;
}
/deep/ .el-textarea {
  width: 300px !important;
}
</style>
