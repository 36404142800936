var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "city-base-cost-wrap" },
    [
      _c("Filtersearch", {
        ref: "Filtersearchref",
        attrs: { fromdata: _vm.formData, list: _vm.Filtersearchlist },
        on: { Refresh: _vm.Refresh, search: _vm.onHandleSearch },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "section",
        { staticClass: "content-wrap" },
        [
          _c("table-list", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingData,
                expression: "loadingData",
              },
            ],
            attrs: { tableData: _vm.tableData },
            on: {
              "submit-form": _vm.submitForm,
              onHandleEdit: _vm.onHandleEdit,
              onHandleChange: _vm.onHandleChange,
            },
          }),
        ],
        1
      ),
      _c("edit-config-dialog", {
        ref: "editConfig",
        attrs: { logisticsList: _vm.logisticsList },
        on: { "submit-form": _vm.submitForm },
      }),
      _c("change-record-dialog", {
        ref: "changeRecordRef",
        attrs: { logisticsList: _vm.logisticsList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }