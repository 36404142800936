var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "编辑",
            visible: _vm.dialogFormVisible,
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents1" },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "集配中心" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.logisticsList.length > 0 &&
                            _vm.logisticsList.find(
                              (item) =>
                                item.id == _vm.detail.logistic_business_id
                            )
                            ? _vm.logisticsList.find(
                                (item) =>
                                  item.id == _vm.detail.logistic_business_id
                              ).name
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "车型" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.detail.vehicle_model_name || "-") + " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "基点" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.threshold_txt || "-") + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "条件" } }, [
                    _vm._v(" " + _vm._s(_vm.detail.symbol_txt || "-") + " "),
                  ]),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "合车费" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: (val) => _vm.handleInput(val, "merge_fare"),
                        },
                        model: {
                          value: _vm.detail.merge_fare,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "merge_fare", $$v)
                          },
                          expression: "detail.merge_fare",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "装卸费" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: (val) => _vm.handleInput(val, "handling_cost"),
                        },
                        model: {
                          value: _vm.detail.handling_cost,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "handling_cost", $$v)
                          },
                          expression: "detail.handling_cost",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "场地费" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: (val) => _vm.handleInput(val, "site_cost"),
                        },
                        model: {
                          value: _vm.detail.site_cost,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "site_cost", $$v)
                          },
                          expression: "detail.site_cost",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 3 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "保险费" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: { input: (val) => _vm.handleInput(val, "premium") },
                        model: {
                          value: _vm.detail.premium,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "premium", $$v)
                          },
                          expression: "detail.premium",
                        },
                      }),
                      _vm._v("元 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { title: "", column: 2 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "变更备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入变更备注",
                          type: "textarea",
                          rows: 3,
                          maxlength: "128",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.remark,
                          callback: function ($$v) {
                            _vm.remark = $$v
                          },
                          expression: "remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitFrom },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }