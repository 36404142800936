var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "vehicle_model_name",
          align: "center",
          "min-width": "120",
          label: "车型",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "threshold_txt", align: "center", label: "吨位基点" },
      }),
      _c("el-table-column", {
        attrs: { prop: "symbol_txt", align: "center", label: "条件" },
      }),
      _c("el-table-column", {
        attrs: { prop: "handling_cost", align: "center", label: "装卸费(元)" },
      }),
      _c("el-table-column", {
        attrs: { prop: "merge_fare", align: "center", label: "合车费(元)" },
      }),
      _c("el-table-column", {
        attrs: { prop: "plank_fee", align: "center", label: "用板费用(个/元)" },
      }),
      _c("el-table-column", {
        attrs: { prop: "site_cost", align: "center", label: "场地费(元)" },
      }),
      _c("el-table-column", {
        attrs: { prop: "premium", align: "center", label: "保险费(元)" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "tdc_owner_status",
          align: "center",
          label: "操作",
          width: "140",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleEdit(scope.row)
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleChange(scope.row)
                      },
                    },
                  },
                  [_vm._v("变更记录")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }