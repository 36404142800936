<template>
  <section class="city-base-cost-wrap">
    <Filtersearch
      ref="Filtersearchref"
      :fromdata="formData"
      :list="Filtersearchlist"
      @Refresh="Refresh"
      @search="onHandleSearch"
    >
      <template v-slot:right>
        <!-- <el-button type="warning" icon="el-icon-upload2" :disabled="disabledExport" @click="onHandleExport">导出</el-button> -->
      </template>
    </Filtersearch>
    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @submit-form="submitForm"
        @onHandleEdit="onHandleEdit"
        @onHandleChange="onHandleChange"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <!-- <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->
    <!-- 分页 end -->

    <!-- 编辑 start -->
    <edit-config-dialog
      ref="editConfig"
      @submit-form="submitForm"
      :logisticsList="logisticsList"
    ></edit-config-dialog>
    <!-- 编辑 end -->

    <!-- 变更记录 start -->
    <change-record-dialog
      ref="changeRecordRef"
      :logisticsList="logisticsList"
    />
    <!-- 变更记录 end -->
  </section>
</template>
<script>
import editConfigDialog from "./modules/editConfig/index";
import TableList from "./modules/table-list/index.vue";
import cloneDeep from "lodash/cloneDeep";
import Filtersearch from "@/components/Filtersearch.vue";
import changeRecordDialog from "./modules/changeRecord/index";
import { postLogisticsBasicFeeList } from "@/api/general/cost.js";

export default {
  name: "city-base-cost",
  components: { editConfigDialog, TableList, Filtersearch, changeRecordDialog },
  data() {
    return {
      Filtersearchlist: [
        {
          type: "select",
          name: "集配中心",
          clearable: false,
          model: "logistic_business_id",
          placeholder: "请选择集配中心",
          selectoptionname: "logisticsList",
          label: "name",
          value: "id",
          selectoption: [],
        },
      ],
      total: 0,
      loadingData: false,
      formData: {
        logistic_business_id: "",
        // page: 1,
        // pageSize: 10,
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [], //列表数据
      logisticsList: [], // 集配中心列表
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    // 列表数据
    async postAjaxLogisticsBasicFeeList() {
      this.loadingData = true;
      try {
        const res = await postLogisticsBasicFeeList(this.formData);
        console.log(res, "postLogisticsBasicFeeList");
        this.tableData = res.data;
      } catch (error) {
        console.log(error, "postLogisticsBasicFeeList");
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList({
          is_online: 1, // 启用中
        });
        this.logisticsList = data;
        this.formData.logistic_business_id = data.length > 0 ? data[0].id : "";
        this.postAjaxLogisticsBasicFeeList();
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "logisticsList"
          ) {
            el.selectoption = [...data];
          }
        });
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {},
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {},
    /**
     * 查询
     */
    onHandleSearch(e) {
      console.log(this.formData, "eee");
      this.formData = e;
      this.postAjaxLogisticsBasicFeeList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.loadingData = true;
      this.getAjaxLogisticsList();
    },
    /**
     * 重置数据
     */
    Refresh() {
      this.formData.logistic_business_id = this.logisticsList[0].id;
      this.postAjaxLogisticsBasicFeeList();
    },
    /**
     * 编辑
     */
    onHandleEdit(e) {
      this.$refs.editConfig.initData(e);
    },
    /** 变更记录 */
    onHandleChange(e) {
      this.$refs.changeRecordRef.initData(e);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.postAjaxLogisticsBasicFeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
.city-base-cost-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .totalWrap {
    margin-top: 10px;
    background: #ffffff;
    padding: 12px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
