<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="vehicle_model_name"
      align="center"
      min-width="120"
      label="车型"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="threshold_txt" align="center" label="吨位基点">
    </el-table-column>
    <el-table-column prop="symbol_txt" align="center" label="条件">
    </el-table-column>
    <el-table-column prop="handling_cost" align="center" label="装卸费(元)">
    </el-table-column>
    <el-table-column prop="merge_fare" align="center" label="合车费(元)">
    </el-table-column>
    <el-table-column prop="plank_fee" align="center" label="用板费用(个/元)">
    </el-table-column>
    <el-table-column prop="site_cost" align="center" label="场地费(元)">
    </el-table-column>
    <el-table-column prop="premium" align="center" label="保险费(元)">
    </el-table-column>
    <el-table-column
      prop="tdc_owner_status"
      align="center"
      label="操作"
      width="140"
    >
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleEdit(scope.row)">编辑</el-button>
        <el-button type="text" @click="onHandleChange(scope.row)"
          >变更记录</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: ["tableData"],
  data() {
    return {};
  },
  methods: {
    // 编辑
    onHandleEdit(e) {
      this.$emit("onHandleEdit", e);
    },
    // 变更记录
    onHandleChange(e) {
      this.$emit("onHandleChange", e);
    },
  },
};
</script>
<style lang="scss" scoped></style>
